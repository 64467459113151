<!-- 搜索抽屉 -->
<template>
    <div class="Drawers">
        <el-drawer :title="titles" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
            <div class="drawers-conter">
                <el-form ref="pages" :model="pages" label-width="80px">
                    <el-form-item label="设备名称">
                        <el-input v-model="pages.T_name" placeholder="请输入Sn/设备名称"></el-input>
                    </el-form-item>
                    <el-form-item label="状态查询">
                        <el-select v-model="pages.T_calssid" placeholder="请选择状态查询">
                            <el-option :label="item.T_name" :value="item.Id" v-for="item,index in selectValue" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="时间区间">
                        <el-date-picker v-model="TimeData" type="datetimerange" @change="TimeChang" range-separator="至"
							value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">立即搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {
	ClassAll
} from "@/api/ClassAll.js"

export default {
    props: {
        titles: {
            type: String,
            default: ''
        },
        drawer: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: 'rtl'//ltr从左往右开,rtl从右往左开,ttb从上往下开,btt从下往上开
        },
    },
    data() {
        return {
            TimeData: [],
            checkAll: true,
            pages: {
                T_name:'',
                T_calssid:'',

                Time_start:'',
                Time_end: '',
                page: 1,
                page_z: 99999,
			},
            dataPowerAll: [], //报警选择
            selectValue : []
        }
    },
    mounted() {
        this.getclassAllApi()
    },
    methods: {
        async getclassAllApi(){
            const reslut = await ClassAll({})
            if (reslut.data.Code==200) {
                this.selectValue = reslut.data.Data.Data || []
                this.selectValue.unshift({
                    Id: '所有分类',
                    T_name: '所有分类'
                })
            }
        },
        TimeChang(e) { //选择完时间确定按钮
            if(e){
                this.pages.Time_start = e[0]
			    this.pages.Time_end = e[1]
            }else{
                this.pages.Time_start = ''
			    this.pages.Time_end =  ''
            }
		},
        setStartTimeFun(data){
            // 创建一个Date对象表示当前时间  
            var now = new Date();  
            // 获取当前日期的年月日  
            var year = now.getFullYear();  
            var month = ('0' + (now.getMonth() + 1)).slice(-2); // 月份从0开始，所以需要+1，并用0填充  
            var date = ('0' + now.getDate()).slice(-2); // 日期用0填充 
            var hours
            var minutes
            var seconds
            if(data=='start'){
                 // 设置时间为0点  
                 var startOfDay = new Date(year, now.getMonth(), date, 0, 0, 0, 0); 
                 // 获取0点的时分秒（虽然它们都是0）  
                hours = startOfDay.getHours().toString().padStart(2, '0');  
                minutes = startOfDay.getMinutes().toString().padStart(2, '0');  
                seconds = startOfDay.getSeconds().toString().padStart(2, '0');  
            } else{
                // 获取0点的时分秒（虽然它们都是0）  
                hours = now.getHours().toString().padStart(2, '0');  
                minutes = now.getMinutes().toString().padStart(2, '0');  
                seconds = now.getSeconds().toString().padStart(2, '0');  
            }
            const t = year + '-' + month + '-' +date + ' ' +hours + ':' + minutes + ':' +seconds
            this.TimeData = ["2023-01-05 00:00:00", "2023-01-10 00:00:00"]
            this.$emit("pick", this.TimeData)
            return t
        },
        onSubmit() {
            var obj = { ...this.pages }
			// obj.T_snid.length =  obj.T_snid.join('|')
            console.log('朝鲜',obj)
            this.$emit('searchFun',obj)
            this.$emit('update:drawer', false)
        },
        handleClose() {
            this.$emit('update:drawer', false)
        }
    }
}
</script>
<style scoped>
::v-deep .el-input__inner {
    background-color: #183d7d !important;
    border: 1px solid #24509b;
    color: #5fc5ff;
    text-align: center;
}
::v-deep .el-form-item__label{
    color: #5fc5ff !important;

}
::v-deep .el-input__inner:focus {
    border: 1px solid #24509b;
}
.model-btn{
    width:150px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: scale(1.1);
    transition: all .5s;
    margin: 80px auto 0 auto;
}
.model-btn:hover{
    transform: scale(1.2);
}
.model-btn>img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;
}
.model-btn>label{
    display: block;
    width: 40px;
    text-align: justify;
    text-align-last: justify;
    z-index: 2;
    letter-spacing: 2px;
    cursor: pointer;
    font-weight: bold;
}

</style>
<style scoped>
::v-deep .el-drawer {
    background-color: #001440 !important;
}

::v-deep .el-drawer__header {
    color: #5fc5ff;
    justify-content: center;
}

::v-deep .el-drawer__header>:first-child {
    flex: 1;
    font-size: 20px;
    font-weight: bold
}
::v-deep .el-input__inner {
    text-align: left !important;
}
::v-deep .el-date-editor .el-range-input {
    color: #fff;
    background: transparent;
}
::v-deep .el-date-editor .el-range-separator {
    color: #5fc5ff;
}
::v-deep .el-checkbox {
    color: #fff;
}
</style>
<style>
    .drawers-conter{
        height: 100%;
        background: url(../../assets/img/bg3.png) no-repeat;
        background-size: 100% 100%;
        background-position: bottom center;
        overflow-y: auto;
        margin:0 20px
    }
</style>