//分类列表select
import {
	axios
} from '../utils/http.js'

//分类列表
export function ClassAll(data) {
	return axios({
		url: '/v3/Class/All',
		method: 'post',
		data:data
	})
}